import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filters',
  standalone: true,
})
export class FiltersPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(arr: any, fields: any, args?: any): any {
    if (!args) {
      return arr;
    }
    return arr.filter((item:any) => {
      for (const key of fields) {
        if (item[key].toLowerCase().indexOf(args.toLowerCase()) > -1) {
          return item;
        }
      }
    });
  }

}
