import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';

export class EncryptionDecryption {
    password= environment.cryptoSecret;
    constructor() {
    }

    encryptData(data:any) {
        if(!data) return false;
        const dataToEncrypt = JSON.stringify(data);
        const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt.trim(), this.password.trim()).toString();
        return encryptedData;
    }

    decryptData(response:any) {
        if(!response.data) return false;
        const decryptedOutput = CryptoJS.AES.decrypt(response.data.trim(),  this.password.trim()).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedOutput);
    }

    encryptSingleField(param:any) {
        if(!param) return false;
        const idToEncrypt = param.toString();
        const encryptedOutput = CryptoJS.AES.encrypt(idToEncrypt.trim(),  this.password.trim()).toString();
        return encryptedOutput;
    }

    decryptSingleField(param:any) {
        if(!param) return false;
            const decryptOutput = CryptoJS.AES.decrypt(param.trim(), this.password.trim()).toString(CryptoJS.enc.Utf8);
            return decryptOutput;
        }
}
