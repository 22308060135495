import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service'; // Import your AuthService
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
// debugger;
    // Exclude the login request from interception
    if (req.url.endsWith('/')) {
      return next.handle(req);
    }


    const token = this.authService.getToken();
     // If no token is present, log out and redirect to the login page
    //  if (!token) {
    //   this.authService.logout();
    //   this.router.navigate(['/login']);
    //   return throwError('No token found, user logged out');
    // }
    // Add the authorization header with the token if available
     authReq = req.clone({
      setHeaders: { Authorization: `${token}` }
    });
    // if (token) {
    //   authReq = req.clone({
    //     setHeaders: { Authorization: `${token}` }
    //   });
    // }

    // Handle errors
    return next.handle(authReq).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          console.log('401')
          this.authService.logout();
          this.router.navigate(['/']);
        } else if (error.status === 403) {
          console.log('403')
          this.authService.logout(); 
          this.router.navigate(['/unauthorized']);
        }
        return throwError(() => error);
      })
    );
  }
}