import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loginData: any;
  
  constructor() {
    this.loginData = JSON.parse(localStorage.getItem('OBDIQ') || '{}');
  }

  getToken(): string | null {
    const storedData = JSON.parse(localStorage.getItem("OBDIQ") || "{}");
    // console.log(storedData);
    return storedData?.login_token;    
  }

  getRoleId(): string | null {
    const storedData = JSON.parse(localStorage.getItem("OBDIQ") || "{}");
    // console.log(storedData?.roleId, 'roleID')
    return storedData?.roleId;
  }

  getLoginData() {
    return this.loginData;
  }

  // methods to check login status for different modules
  isAdminLoggedIn(): boolean {
    // debugger;
    return this.getRoleId() === '1';
  }

  isPartnerLoggedIn(): boolean {
    // debugger;
    return this.getRoleId() === '2';
  }

  isAccountLoggedIn(): boolean {
    // debugger;
    return this.getRoleId() === '3';
  }

  isLocationLoggedIn(): boolean {
    // debugger;
    return this.getRoleId() === '4';
  }

  logout(): void {
    localStorage.removeItem('OBDIQ');
    localStorage.clear();
    // Perform additional logout operations if necessary
  }
}
