import { inject } from '@angular/core';
import { CanMatchFn, Route, UrlSegment, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';


export const roleGuard: CanMatchFn = (route: Route, segments: UrlSegment[]) => {

  const authService = inject(AuthService); // Inject AuthService
  const router = inject(Router); // Inject Router
  const roleId = authService.getRoleId(); // Get the user's role ID
  // debugger;
  if (route.path === 'admin' && roleId === '1') {
    return true;
  } else if (route.path === 'partner' && roleId === '2') {
    return true;
  } else if (route.path === 'account' && roleId === '3') {
    return true;
  } else if (route.path === 'location' && roleId === '4') {
    return true;
  } else {
    // Redirect to unauthorized page if roleId doesn't match
    router.navigate(['/unauthorized']);
    // router.navigate(['/']);
    return false;
  }
};
