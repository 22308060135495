import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { roleGuard } from './guards/role.guard'; 

const routes: Routes = [

  // {
  //   path: '',
  //   loadChildren: () => import('./pages/routes').then((m) => m.routes)
  // },

  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full'
  // },

  // {
  //   path: 'login',
  //   loadComponent: () => import('./pages/login/login.component').then((m) => m.LoginComponent),
  // },

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.component').then((m) => m.LoginComponent),
  },

  {
    path: 'reset-password/:id',
    loadComponent: () => import('./pages/reset-password/reset-password.component').then(m => m.ResetPasswordComponent),
  },

  {
    path: "admin",
    loadChildren: () => import('./admin/admin.routes').then((m) => m.routes),
    canLoad: [roleGuard],
  },  
  {
    path: "partner",
    loadChildren: () => import('./partners/partner-level.routes').then((m) => m.routes),
    canLoad: [roleGuard],
  }, 
  {
    path: "account",
    loadChildren: () => import('./accounts/account-level.routes').then((m) => m.routes),
    canLoad: [roleGuard],
  },
  {
    path: "location",
    loadChildren: () => import('./locations/location-level.routes').then((m) => m.routes),
    canLoad: [roleGuard],
  },    
  {
    path: "report",
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule)
  },

  {
    path: 'theme',
    loadChildren: () => import('./shared/theme/routes').then((m) => m.routes)
  },
  {
    path: 'base',
    loadChildren: () => import('./shared/base/routes').then((m) => m.routes)
  },
  {
    path: 'buttons',
    loadChildren: () => import('./shared/buttons/routes').then((m) => m.routes)
  },
  {
    path: 'forms',
    loadChildren: () => import('./shared/forms/routes').then((m) => m.routes)
  },
  {
    path: 'icons',
    loadChildren: () => import('./shared/icons/routes').then((m) => m.routes)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./shared/notifications/routes').then((m) => m.routes)
  },
  {
    path: 'widgets',
    loadChildren: () => import('./shared/widgets/routes').then((m) => m.routes)
  },
  {
    path: 'charts',
    loadChildren: () => import('./shared/charts/routes').then((m) => m.routes)
  },

  {
    path: 'unauthorized',
    loadComponent: () => import('./unauthorized/unauthorized.component').then(m => m.UnauthorizedComponent),
  },
  
  {
    path: 'forbidden',
    loadComponent: () => import('./forbidden/forbidden.component').then(m => m.ForbiddenComponent),
  },

  {
    path: '**',
    redirectTo: 'unauthorized',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
