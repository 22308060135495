export const environment = {
  production: true,
  apiUrl: `${window.location.origin}/api/v1/`,
  frontUrl: `${window.location.origin}`,
  LIMIT: 20,
  cryptoSecret: "LMSCARDR@C2022",
  //S3URL:"https://obdiq-development.s3.amazonaws.com",
  socketUrl:`${window.location.origin}`,
  GOOGLE_API_KEY: "AIzaSyBv8e3PAfbwzvUC6gtqLW2QYuV38VlteNs",
};
