import { Injectable, inject, EventEmitter } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import {of } from 'rxjs';
import { map} from 'rxjs/operators';
import { environment } from "../../environments/environment";
const findPlaceURL = 'https://maps.googleapis.com/maps/api/place/findplacefromtext/json';
const placeDetail =  'https://maps.googleapis.com/maps/api/place/details/json';


@Injectable()
export class GeoCodeService {
  private http = inject(HttpClient);

	search(term: string) {
		if (term === '') {
			return of([]);
		}

    let params = new HttpParams() 
    .set('key', environment.GOOGLE_API_KEY);
		return this.http
			.get<[any, string[]]>(`${findPlaceURL}?input=${term}&inputtype=textquery&fields=formatted_address,name,rating,opening_hours,place_id`, { params: params })
			.pipe(map((response:any) => response.candidates));
	}

	getPlace(term: string) {
    let params = new HttpParams() 
    .set('key', environment.GOOGLE_API_KEY);
		return this.http
			.get<[any, string[]]>(`${placeDetail}?place_id=${term}&fields=formatted_address,address_components,geometry`, { params: params })
			
	}
  
}
